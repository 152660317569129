import React from 'react';

const EventInfoHome = () => {

    return (
        <div className="media-container">

        </div>
    );
};

export default EventInfoHome;