import React from 'react';

const TeamSchedule = ({ teamInfo }) => {
    return (
        <div>
        </div>


    );
};
export default TeamSchedule;